<app-navbar-two></app-navbar-two>

<div class="blog-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Our Blogs</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Blogs</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="blog-section blog-details-area pt-100 pb-70">
    <div class="container">               
        <div class="row">
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-md-6">
                        <div class="blog-card">
                            <div class="blog-img">
                                <a routerLink="/blog-details"><img src="assets/img/blog/1.jpg" alt="blog image"></a>
                                <div class="blog-date">
                                    <span>21 Feb</span>
                                </div>
                            </div>

                            <div class="blog-text">
                                <a routerLink="/blog-details"><h3>The next genaration IT will change the world</h3></a>
                                <div class="post-info">
                                    <img src="assets/img/blog/author-1.png" alt="blog author">
                                    <a routerLink="/blog"><p>Aikin Ward</p></a>
                                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="blog-card">
                            <div class="blog-img">
                                <a routerLink="/blog-details"><img src="assets/img/blog/2.jpg" alt="blog image"></a>
                                <div class="blog-date">
                                    <span>22 Feb</span>
                                </div>
                            </div>

                            <div class="blog-text">
                                <a routerLink="/blog-details"><h3>Don't buy a tech gift until you read these rules image</h3></a>
                                <div class="post-info">
                                    <img src="assets/img/blog/author-2.png" alt="blog author">
                                    <a routerLink="/blog"><p>John Smith</p></a>
                                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="blog-card">
                            <div class="blog-img">
                                <a routerLink="/blog-details"><img src="assets/img/blog/3.jpg" alt="blog image"></a>
                                <div class="blog-date">
                                    <span>23 Feb</span>
                                </div>
                            </div>

                            <div class="blog-text">
                                <a routerLink="/blog-details"><h3>Don't buy a tech gift until you read these rules image</h3></a>
                                <div class="post-info">
                                    <img src="assets/img/blog/author-3.png" alt="blog author">
                                    <a routerLink="/blog"><p>Sarah Tylor</p></a>
                                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="blog-card">
                            <div class="blog-img">
                                <a routerLink="/blog-details"><img src="assets/img/blog/5.jpg" alt="blog image"></a>
                                <div class="blog-date">
                                    <span>22 Feb</span>
                                </div>
                            </div>

                            <div class="blog-text">
                                <a routerLink="/blog-details"><h3>Content writing demand day by day increasing</h3></a>
                                <div class="post-info">
                                    <img src="assets/img/blog/author-1.png" alt="blog author">
                                    <a routerLink="/blog"><p>John Smith</p></a>
                                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="blog-card">
                            <div class="blog-img">
                                <a routerLink="/blog"><img src="assets/img/blog/6.jpg" alt="blog image"></a>
                                <div class="blog-date">
                                    <span>23 Feb</span>
                                </div>
                            </div>

                            <div class="blog-text">
                                <a routerLink="/blog-details"><h3>The next genaration IT will change the world</h3></a>
                                <div class="post-info">
                                    <img src="assets/img/blog/author-2.png" alt="blog author">
                                    <a routerLink="/blog"><p>Sarah Tylor</p></a>
                                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="blog-card">
                            <div class="blog-img">
                                <a routerLink="/blog-details"><img src="assets/img/blog/1.jpg" alt="blog image"></a>
                                <div class="blog-date">
                                    <span>21 Feb</span>
                                </div>
                            </div>

                            <div class="blog-text">
                                <a routerLink="/blog-details"><h3>Content writing demand day by day increasing</h3></a>
                                <div class="post-info">
                                    <img src="assets/img/blog/author-3.png" alt="blog author">
                                    <a routerLink="/blog"><p>Aikin Ward</p></a>
                                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="blog-card">
                            <div class="blog-img">
                                <a routerLink="/blog"><img src="assets/img/blog/7.jpg" alt="blog image"></a>
                                <div class="blog-date">
                                    <span>22 Feb</span>
                                </div>
                            </div>

                            <div class="blog-text">
                                <a routerLink="/blog-details"><h3>The next genaration IT will change the world</h3></a>
                                <div class="post-info">
                                    <img src="assets/img/blog/author-1.png" alt="blog author">
                                    <a routerLink="/blog"><p>John Smith</p></a>
                                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="blog-card">
                            <div class="blog-img">
                                <a routerLink="/blog"><img src="assets/img/blog/8.jpg" alt="blog image"></a>
                                <div class="blog-date">
                                    <span>23 Feb</span>
                                </div>
                            </div>

                            <div class="blog-text">
                                <a routerLink="/blog-details"><h3>Don't buy a tech gift until you read these rules image</h3></a>
                                <div class="post-info">
                                    <img src="assets/img/blog/author-2.png" alt="blog author">
                                    <a routerLink="/blog"><p>Sarah Tylor</p></a>
                                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>                           
                </div>
            </div>

            <div class="col-lg-4">
                <div class="blog-search">
                    <form>
                        <input type="text" placeholder="Search">
                        <button><i class="flaticon-search"></i></button>
                    </form>
                </div>

                <div class="recent-blog">
                    <h3>Recent Blogs</h3>

                    <article class="recent-post">
                        <a routerLink="/blog"><img src="assets/img/blog/recent-3.png" alt="recent post image"></a>
                        <h3><a routerLink="/blog">Content writing demand day by day increasing</a></h3>
                        <ul>
                            <li><i class="icofont-user-alt-3"></i> Admin</li>
                            <li><i class="icofont-user-alt-3"></i> Jan 03, 2023</li>
                        </ul>
                    </article>

                    <article class="recent-post">
                        <a routerLink="/blog"><img src="assets/img/blog/recent-2.png" alt="recent post image"></a>
                        <h3><a routerLink="/blog">Don't buy a tech gift until you read these rules image</a></h3>
                        <ul>
                            <li><i class="icofont-user-alt-3"></i> Admin</li>
                            <li><i class="icofont-user-alt-3"></i> Jan 03, 2023</li>
                        </ul>
                    </article>

                    <article class="recent-post">
                        <a routerLink="/blog"><img src="assets/img/blog/recent-3.png" alt="recent post image"></a>
                        <h3><a routerLink="/blog">The next genaration IT will change the world</a></h3>
                        <ul>
                            <li><i class="icofont-user-alt-3"></i> Admin</li>
                            <li><i class="icofont-user-alt-3"></i> Jan 03, 2023</li>
                        </ul>
                    </article>
                </div>

                <div class="blog-category">
                    <h3>Category</h3>

                    <ul>
                        <li><a routerLink="/blog">Business Strategy <i class="icofont-arrow-right"></i></a></li>
                        <li><a routerLink="/blog">Full Secured <i class="icofont-arrow-right"></i></a></li>
                        <li><a routerLink="/blog">Business Policy <i class="icofont-arrow-right"></i></a></li>
                        <li><a routerLink="/blog">Software Developmet <i class="icofont-arrow-right"></i></a></li>
                        <li><a routerLink="/blog">Time Saving <i class="icofont-arrow-right"></i></a></li>
                        <li><a routerLink="/blog">UIUX Design <i class="icofont-arrow-right"></i></a></li>
                    </ul>
                </div>
                
                <div class="tags">
                    <h3>Tags</h3>
                    <a routerLink="/blog">UX/UX</a>
                    <a routerLink="/blog">Web</a>
                    <a routerLink="/blog">App</a>
                    <a routerLink="/blog">Design</a>
                    <a routerLink="/blog">Business</a>
                    <a routerLink="/blog">UX</a>
                    <a routerLink="/blog">Design</a>
                    <a routerLink="/blog">Web</a>
                </div>
            </div>
        </div>
    </div>
</section>