<app-navbar-one></app-navbar-one>

<div class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Your Business Our Responsibility</h1>
                    <p>We take care of the details, so you can focus on success. Also, We handle the intricacies, letting you soar to success with confidence.</p>

                    <div class="theme-button">
                        <a routerLink="/" class="default-btn">Get Started</a>
                        <!-- <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" class="video-btn popup-vimeo"><i class="bx bx-play"></i> Watch video</a> -->
                    </div>
                </div>

                <div class="banner-image">
                    <img src="assets/img/custom/banner-1.png" alt="banner image">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="video-section pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="video-text">
                    <!-- <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" class="popup-vimeo"><i class='bx bx-play'></i></a>
                    <p>Watch our video and know more about us</p> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="theory-section">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>My Theory is Customer Service is First</h2>
                    <p>Customer service is paramount – it's the cornerstone of our business philosophy, where satisfied clients always come first.</p>
                    <div class="signature-box">
                        <!-- <img src="assets/img/signature.png" alt="signature"> -->
                        <p>Chakrapani</p>
                        <span>Founder and CEO</span>
                    </div>
                </div>
            </div>

            <div class="theory-img">
                <!-- <img src="assets/img/theory-img.png" alt="theory image"> -->
            </div>
        </div>
    </div>
</div>

<section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/custom/about-2.png" alt="about image">
                </div>
            </div>

            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>The Modern Way to Make Succesful Business</h2>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-winner icon-one"></i>
                                <p>Award Winner</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-customer-service icon-two"></i>
                                <p>24/7 Support</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake icon-three"></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                    </div>
                    <p>Embracing a contemporary approach to business: Achieving success through innovation and adaptability. In today's dynamic landscape, staying ahead is our guiding principle. Discover the modern path to business triumph.</p>
                    <div class="theme-button">
                       <a routerLink="/about" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="features-section pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Services</span>
            <h2>Our Primary Services</h2>
            <p>Our primary services encompass a diverse array of solutions tailored to your needs, designed for your success. We're here to empower your journey with expertise, precision, and unwavering commitment.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-one">
                    <i class="flaticon-technical-support"></i>
                    <h3>Data Science & ML</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-two">
                    <i class="flaticon-megaphone"></i>
                    <h3>Marketing</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div> -->

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-three">
                    <i class="flaticon-optimization"></i>
                    <h3>Web Development</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-four">
                    <i class="flaticon-first-place"></i>
                    <h3>Full Secured</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-five">
                    <i class="flaticon-alarm-clock"></i>
                    <h3>Time Saving</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div> -->

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six">
                    <i class="flaticon-newsletter"></i>
                    <h3>Email Marketing</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="service-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 service-img"></div>

            <div class="col-md-7 offset-md-5 p-0">
                <div class="service-text">
                    <h2>We Understand the User Need and Those Services for Them</h2>
                    <ul>
                        <li><i class="flaticon-checkmark"></i> Building Networking</li>
                        <li><i class="flaticon-checkmark"></i> Software Development</li>
                        <li><i class="flaticon-checkmark"></i> Business Strategy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Team Member</span>
            <h2>Meet Our Expert Team Member</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/custom/chakra.png" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <!-- <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul> -->
                            </div>
                            <h3>Chakrapani</h3>
                            <p>Business Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/custom/bharadwaj.png" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <!-- <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul> -->
                            </div>
                            <h3>Bharadwaj</h3>
                            <p>DS & ML Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/custom/purushotham.png" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <!-- <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul> -->
                            </div>
                            <h3>Purushotham</h3>
                            <p>Web Dev Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- <div class="testimonial-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 offset-lg-1 pt-100 pb-100">
                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="slider-items">
                        <img src="assets/img/testimonial/1.png" alt="testimonial image">
                        <h3>Adision Smith</h3>
                        <span>Designer</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra</p>
                    </div>

                    <div class="slider-items">
                        <img src="assets/img/testimonial/2.png" alt="testimonial image">
                        <h3>John Smith</h3>
                        <span>Digital Marketer</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra</p>
                    </div>

                    <div class="slider-items">
                        <img src="assets/img/testimonial/3.png" alt="testimonial image">
                        <h3>Anderson</h3>
                        <span>Designer</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 p-0">
                <div class="testimonial-image"></div>
            </div>
        </div>
    </div>
</div> -->

<!-- <section class="portfolio-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Portfolio</span>
            <h2>Latest Works For Clients</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/1.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Online Business</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/2.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Digital Marketing</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/3.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Business Research</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/4.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Content Writing</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/5.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Virtual Assistant Service</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/6.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>SEO Service</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="theme-button text-center">
            <a routerLink="/portfolio" class="default-btn">Load More</a>
        </div>
    </div>
</section> -->

<div class="progress-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    <h2>We Always Try to be the Best Support to Our Clients as Possible</h2>
                    <p>We continuously strive to be the utmost support for our clients, going above and beyond to meet their needs and exceed expectations. Our commitment to excellence is unwavering in every aspect of our service.</p>
                    <div class="progress-inner">
                        <p>Software Development</p>
                        <span>70%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="progress-inner">
                        <p>Digital Marketing</p>
                        <span>85%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="progress-inner">
                        <p>Business Management</p>
                        <span>95%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Blog</span>
            <h2>Our Latest Blogs</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/1.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>21 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">The next genaration IT will change the world</a></h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-1.png" alt="blog author">
                            <a routerLink="/"><p>Aikin Ward</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/2.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>22 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">Content writing demand day by day increasing</a></h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-2.png" alt="blog author">
                            <a routerLink="/"><p>John Smith</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/3.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>23 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">Don't buy a tech gift until you read these rules image</a></h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-3.png" alt="blog author">
                            <a routerLink="/"><p>Sarah Tylor</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <div class="contact-section pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 contact-img">
                <img src="assets/img/contact.jpg" alt="contact">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us. We will get you</p>
                    </div>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn contact-btn">Send Message</button>
                            </div>
                        </div>
                    </form>

                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div> -->
