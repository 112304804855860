<app-navbar-one></app-navbar-one>

<div class="service-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Service Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Service  Details</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="service-style-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="service-details-area">
                    <div class="service-details-img">
                        <img src="assets/img/service-details.jpg" alt="service details">
                    </div>
                    
                    <h3>Online Business</h3>

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen</p>

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                    <div class="row">
                        <div class="col-lg-6">
                            <img src="assets/img/service-details-2.jpg" class="service-btm-img" alt="service details">
                        </div>

                        <div class="col-lg-6">
                            <img src="assets/img/service-details-3.jpg" class="service-btm-img" alt="service details">
                        </div>
                    </div>

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="blog-search">
                    <form>
                        <input type="text" placeholder="Search">
                        <button><i class="flaticon-search"></i></button>
                    </form>
                </div>

                <div class="service-category">
                    <h3>Services</h3>

                    <ul>
                        <li><a routerLink="/service-details">Business Strategy <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/service-details">Online Business <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/service-details">Business Policy <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/service-details">Software Developmet <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/service-details">Marketing <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/service-details">UI/UX Design <i class="icofont-arrow"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>