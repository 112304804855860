<app-navbar-two></app-navbar-two>

<div class="blog-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Blog Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Blog Details</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-description">
                    <div class="article-image">
                        <img src="assets/img/blog/blog-details.jpg" alt="blog image">
                        <div class="blog-date">
                            <span>21 Feb</span>
                        </div>
                    </div>

                    <div class="article-info">
                        <ul>
                            <li><i class="icofont-user-alt-2"></i> Sarah Tylor</li>
                            <li><i class="icofont-user-alt-2"></i> 3 min Read</li>
                            <li><i class="icofont-user-alt-2"></i> Marketing</li>
                        </ul>
                    </div>
                    <h3 class="article-title">The next genaration IT will change the business world</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices.</p>
                    <p>Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
                    <div class="row">
                        <div class="col-sm-6">
                            <img src="assets/img/blog/4.jpg" class="article-bottom-img" alt="blog image">
                        </div>
                        <div class="col-sm-6">
                            <img src="assets/img/blog/5.jpg" class="article-bottom-img" alt="blog image">
                        </div>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                    <div class="blog-nav">
                        <div class="prev-btn">
                            <a href="#">Previous</a>
                        </div>

                        <div class="next-btn text-end">
                            <a href="#">Next</a>
                        </div>
                    </div>
                    <div class="blog-comment">
                        <h3>Drop your comment</h3>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Full Name">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                            <div class=" col-md-12">
                                <div class="form-group">
                                    <textarea class="message-field" cols="30" rows="5" placeholder="Comment Here"></textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button type="submit" class="default-btn contact-btn">Post A Comment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="blog-search">
                    <form>
                        <input type="text" placeholder="Search">
                        <button><i class="flaticon-search"></i></button>
                    </form>
                </div>

                <div class="recent-blog">
                    <h3>Recent Blogs</h3>

                    <article class="recent-post">
                        <a routerLink="/blog"><img src="assets/img/blog/recent-3.png" alt="recent post image"></a>
                        <h3><a routerLink="/blog">Content writing demand day by day increasing</a></h3>
                        <ul>
                            <li><i class="icofont-user-alt-3"></i> Admin</li>
                            <li><i class="icofont-user-alt-3"></i> Jan 03, 2023</li>
                        </ul>
                    </article>

                    <article class="recent-post">
                        <a routerLink="/blog"><img src="assets/img/blog/recent-2.png" alt="recent post image"></a>
                        <h3><a routerLink="/blog">Don't buy a tech gift until you read these rules image</a></h3>
                        <ul>
                            <li><i class="icofont-user-alt-3"></i> Admin</li>
                            <li><i class="icofont-user-alt-3"></i> Jan 03, 2023</li>
                        </ul>
                    </article>

                    <article class="recent-post">
                        <a routerLink="/blog"><img src="assets/img/blog/recent-3.png" alt="recent post image"></a>
                        <h3><a routerLink="/blog">The next genaration IT will change the world</a></h3>
                        <ul>
                            <li><i class="icofont-user-alt-3"></i> Admin</li>
                            <li><i class="icofont-user-alt-3"></i> Jan 03, 2023</li>
                        </ul>
                    </article>
                </div>

                <div class="blog-category">
                    <h3>Category</h3>

                    <ul>
                        <li><a routerLink="/blog">Business Strategy <i class="icofont-arrow-right"></i></a></li>
                        <li><a routerLink="/blog">Full Secured <i class="icofont-arrow-right"></i></a></li>
                        <li><a routerLink="/blog">Business Policy <i class="icofont-arrow-right"></i></a></li>
                        <li><a routerLink="/blog">Software Developmet <i class="icofont-arrow-right"></i></a></li>
                        <li><a routerLink="/blog">Time Saving <i class="icofont-arrow-right"></i></a></li>
                        <li><a routerLink="/blog">UIUX Design <i class="icofont-arrow-right"></i></a></li>
                    </ul>
                </div>
                
                <div class="tags">
                    <h3>Tags</h3>
                    <a routerLink="/blog">UX/UX</a>
                    <a routerLink="/blog">Web</a>
                    <a routerLink="/blog">App</a>
                    <a routerLink="/blog">Design</a>
                    <a routerLink="/blog">Business</a>
                    <a routerLink="/blog">UX</a>
                    <a routerLink="/blog">Design</a>
                    <a routerLink="/blog">Web</a>
                </div>
            </div>
        </div>
    </div>
</div>